import { ChangeDetectionStrategy, Component, Injectable } from '@angular/core';
import { AuthenticationService } from '@auth-n/authentication.service';

@Injectable({
  providedIn: 'root'
})
@Component({
  selector: 'app-sso-landing',
  templateUrl: './sso-landing.component.html',
  styleUrl: './sso-landing.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SsoLandingComponent {
  constructor(private authenticationService: AuthenticationService) {
    this.authenticationService.checkForSsoCallbackUser();
  }
}

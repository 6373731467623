<div class="password-wrapper">
  <app-input
    tid="{{ tId }}"
    type="{{ passwordType }}"
    formPropertyName="{{ formPropertyName }}"
    label="{{ label }}"
    placeholder="{{ placeholder }}"
    autocomplete="off"
  ></app-input>
  <app-icon color="primary-action" (click)="togglePasswordVisibility()">{{ passwordIcon }}</app-icon>
</div>

<ng-container *ngIf="currentStep === 'RequestToken'" [formGroup]="resetPasswordRequestFormGroup">
  <app-h4>Reset your password</app-h4>
  <form (ngSubmit)="requestResetPassword()" tid="request-reset-password-form">
    <app-input
      tid="username"
      type="email"
      [emailField]="true"
      formPropertyName="email"
      label="Email"
      placeholder="Email"
      appAutoFocus
    ></app-input>

    <div class="form-actions">
      <app-button style="float: left" theme="primary-action" (click)="cancel()" basicButton="true">Back</app-button>
      <app-button style="float: right" theme="primary-action" type="submit" [loading]="inFlight$ | async" tid="submit-button">
        Continue
      </app-button>
    </div>
  </form>
</ng-container>

<ng-container *ngIf="currentStep === 'CompleteResetPassword'" [formGroup]="resetPasswordFormGroup">
  <form (ngSubmit)="completeResetPassword()" tid="complete-reset-password-form">
    <app-password-reset-controls #passwordResetControls></app-password-reset-controls>
    <div class="form-actions">
      <app-button theme="primary-action" (click)="cancel()" basicButton="true">Back</app-button>
      <app-button theme="primary-action" type="submit" [loading]="inFlight$ | async" tid="submit-button">Create Password</app-button>
    </div>
  </form>
</ng-container>

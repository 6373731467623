<app-form-modal-template title="Change Password" (closeEvent)="close()">
  <form [formGroup]="resetPasswordFormGroup" body-content>
    <app-h4>Enter existing password</app-h4>

    <app-password-input
      label="Current password"
      tId="current-password"
      formPropertyName="currentPassword"
      placeholder="Current password"
      required
      class="password-input"
    ></app-password-input>

    <app-password-reset-controls class="pt-4" #passwordResetControls></app-password-reset-controls>
  </form>

  <div footer-content-left>
    <app-button type="button" tabindex="0" (click)="close()" tid="cancel-button">Cancel</app-button>
  </div>
  <div footer-content-right>
    <app-button theme="primary-action" (click)="changePasswordSubmit()" tabindex="0" tid="confirm-button">Save</app-button>
  </div>
</app-form-modal-template>

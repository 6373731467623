import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { environment } from '@env/environment';
import { LoggingService } from '@logging/logging.service';
import { ActiveAuthenticationStateService } from '@auth-n/state/active-authentication.state-service';

@Injectable()
export class ResetPasswordService {
  protected apiRoot = `${environment.apiPaths.authentication}/reset-password`;

  constructor(
    protected logger: LoggingService,
    private httpClient: HttpClient,
    private authenticationState: ActiveAuthenticationStateService
  ) {}

  requestResetPassword$(email: string): Observable<void> {
    this.authenticationState.inFlight = true;
    return this.httpClient.post<void>(`${this.apiRoot}/request`, { email }).pipe(
      tap((response) => {
        this.logger.trace('RESET PASSWORD SERVICE: requested response: ', response);
      }),
      finalize(() => {
        this.authenticationState.inFlight = false;
      })
    );
  }

  resetPassword$(token: string, password: string): Observable<void> {
    this.authenticationState.inFlight = true;
    return this.httpClient.post<void>(`${this.apiRoot}`, { token, password }).pipe(
      tap((response) => {
        this.logger.trace('RESET PASSWORD SERVICE: reset response: ', response);
      }),
      finalize(() => {
        this.authenticationState.inFlight = false;
      })
    );
  }
}

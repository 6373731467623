<ng-container *ngIf="formGroup" [formGroup]="formGroup">
  <app-h4>Enter new password</app-h4>

  <app-password-input label="Password" tId="password" formPropertyName="password" placeholder="Password"></app-password-input>

  <ul>
    <li [ngClass]="{ passed: passwordConformsTo('min-length') }">
      <mat-icon>check_circle</mat-icon>
      <mat-icon>check_circle_outline</mat-icon>
      Minimum eight characters in length
    </li>
    <li [ngClass]="{ passed: passwordConformsTo('upper-case-letter') }">
      <mat-icon>check_circle</mat-icon>
      <mat-icon>check_circle_outline</mat-icon>
      At least one upper case letter
    </li>
    <li [ngClass]="{ passed: passwordConformsTo('lower-case-letter') }">
      <mat-icon>check_circle</mat-icon>
      <mat-icon>check_circle_outline</mat-icon>
      At least one lower case letter
    </li>
    <li [ngClass]="{ passed: passwordConformsTo('digit') }">
      <mat-icon>check_circle</mat-icon>
      <mat-icon>check_circle_outline</mat-icon>
      At least one digit
    </li>
    <li [ngClass]="{ passed: passwordConformsTo('special-char') }">
      <mat-icon>check_circle</mat-icon>
      <mat-icon>check_circle_outline</mat-icon>
      At least one special character
    </li>
  </ul>

  <password-strength-meter tid="password-strength-meter" [password]="password$ | async"></password-strength-meter>

  <app-password-input
    label="Confirm Password"
    tId="confirm-password"
    formPropertyName="confirmPassword"
    placeholder="Reenter password"
    style="margin-top: 2.4rem; display: block"
  ></app-password-input>
</ng-container>
